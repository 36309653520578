.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .icon {
    display: inline-block;
    animation: bounce 2s infinite;
  }
  
  .face {
    width: 100px;
    height: 100px;
    background: #fcc318;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
  }


  .eyes{
    margin-bottom: 30px;

  }
  
  .eye {
    width: 20px;
    height: 20px;
    background: #333;
    border-radius: 50%;
    position: absolute;
    top: 20px;
  }
  
  .eye:first-child {
    left: 25px;
  }
  
  .eye:last-child {
    right: 25px;
  }
  
  .mouth {
    width: 50px;
    height: 5px;
    background: #333;
    /* border-radius: 50px 50px 0px 0px; */
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);.no-results {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: center;
        font-family: Arial, sans-serif;
      }
      
      .icon {
        display: inline-block;
        animation: bounce 2s infinite;
      }
      
      .face {
        width: 100px;
        height: 100px;
        background: #f0f0f0;
        border-radius: 50%;
        position: relative;
        margin: 0 auto;
      }
      
      .eye {
        width: 20px;
        height: 20px;
        background: #e71919;
        border-radius: 50%;
        position: absolute;
        top: 35px;
      }
      
      .eye:first-child {
        left: 25px;
      }
      
      .eye:last-child {
        right: 25px;
      }
      
      .mouth {
        width: 50px;
        height: 5px; /* Adjusted height for neutral expression */
        background: #333;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
      }
      
      .message {
        margin-top: 20px;
        font-size: 20px;
        color: #333;
      }
      
      @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-30px);
        }
        60% {
          transform: translateY(-15px);
        }
      }
      
  }
  
  .message {
    margin-top: 20px;
    font-size: 20px;
    color: #333;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  